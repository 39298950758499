@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: "Pixelar Regular W01 Regular";
    src: url("./fonts/4d8bd4f0-ce40-4f34-a0b8-49bc51024ea8.woff2") format("woff2"), url("./fonts/9c200a14-d264-405c-86bf-d27b840e86be.woff") format("woff");
}

body {
    margin: 0;
    font-family: "Pixelar Regular W01 Regular" !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}